﻿var OverDrive = OverDrive || {};

OverDrive.LibrarySearch = function (reserveId, timeoutText, genericError, dataTableSourceUrl, geocodeFailure, getPositionUrl, linkToLibrariesPage, findLibrariesLink, titleId) {

    var reserveId = reserveId;
    var tableSelector = $("#librariesTableByPosition");
    var dataTableSourceUrl = dataTableSourceUrl;

    var setupDataTable = function (queryString) {
        $(tableSelector).empty();
        $(tableSelector).removeClass('empty');
        var loadingText = window.OverDrive.SiteCopy.get("findInLibrariesPage.loading");
        $(tableSelector).append('<tr class="table-loading"><td>' + loadingText + '</td></tr>');

        $.ajax({
            url: dataTableSourceUrl + queryString,
            success: function (result) {
                if (result.aaData.length > 0) {
                    fillTableData(result);
                } else {
                    noResultsTable(emptyTableString(), linkToLibrariesPage);
                }
            },
            timeout: 10000,
            error: function (err) {
                if (err.statusText === 'timeout') {
                    alert(timeoutText);
                } else {
                    alert(genericError);
                }

                noResultsTable();
            }
        });

        if (linkToLibrariesPage) addLibrariesLink();
    }
      
    var fillTableData = function (result) {
        $(tableSelector).empty();

        var count = 0;

        $.each(result.aaData, function (index, val) {
            var distanceHtml = "";
            if (val.distance > 0) distanceHtml = '<span class="icon icon-map-pin"></span> <span>' + window.OverDrive.SiteCopy.get("findInLibrariesPage.libraryDistance", { "distance": val.distance }) + '</span>';

            var gacHtml = "";
            if (val.isIdcEnabled) {
                let idcUrl;
                // neither of these options work properly.  Eventually we want to add support to Zelda for IDC links to clear this up.  Ask Lyle Hennicke about it.
                if (window.OverDrive.featureManager.isEnabled('use-zelda-links')) {
                    idcUrl = val.libraryUrl;
                } else {
                    // this only works if libraryFinder gave us the lightning url, which it doesn't usually.
                    idcUrl = 'http://' + val.libraryUrl + '/account/ozone/sign-in?forward=%2F&showIdcSignUp=true';
                }
                gacHtml = '<a href="' + idcUrl + '" aria-label="' + window.OverDrive.SiteCopy.get("ariaLabels.getInstantCard", { "libraryName": val.consortium }) + '" target="_blank" rel="noreferrer" class="cardLink"><img src="/Content/img/icons/svg/holding-phone-icon.svg" alt=""/> ' + window.OverDrive.SiteCopy.get("findInLibrariesPage.getInstantCard") + '</a>';
            } else if (val.branchCardAcquisitionUrl != "" && val.branchCardAcquisitionUrl != null) {
                gacHtml = '<a href="' + val.branchCardAcquisitionUrl + '" target="_blank" rel="noreferrer" aria-label="' + window.OverDrive.SiteCopy.get("ariaLabels.getLibraryCard", { "libraryName": val.consortium }) + '" class="cardLink needACard">' + window.OverDrive.SiteCopy.get("findInLibrariesPage.needLibraryCard") + '</a>';
            }

            var libTypeClass = val.isPublic ? "isPublic" : "isSchool";
            var buttonText = val.isPublic ? (window.OverDrive.featureManager.isEnabled('redirect-to-libby') ? window.OverDrive.SiteCopy.get("detailsPage.savedLibraries.findItInLibby") : window.OverDrive.SiteCopy.get("detailsPage.savedLibraries.viewLibrarySite")) : window.OverDrive.SiteCopy.get("detailsPage.savedLibraries.viewSchoolSite");

            var trClass = '';
            if ((count + 1) > window.OverDrive.minNumOfLibraries) trClass = "hiddenLibrary";

            let titleAtLibrarySiteUrl;
            if (window.OverDrive.featureManager.isEnabled('use-zelda-links')) {
                titleAtLibrarySiteUrl = val.isPublic && window.OverDrive.featureManager.isEnabled('redirect-to-libby') ? `${val.libraryUrl}&titleId=${titleId}&app=libby` : `${val.libraryUrl}&titleId=${titleId}`;
            } else {
                titleAtLibrarySiteUrl = `https://${val.libraryUrl}/ContentDetails.htm?id=${reserveId}`;
            }
            var tableSelectorString = '';
            tableSelectorString += '<tr tabindex="0" class="' + trClass + ' ' + libTypeClass + '">';
            tableSelectorString += '<td class="adjustLibraryColWidth">';
            tableSelectorString += '<div class="mobileView">';
            tableSelectorString += '<h6>' + window.OverDrive.SiteCopy.get("findInLibrariesPage.libraryNameLabel") + ': </h6>';
            tableSelectorString += '<a class="libraryLink" aria-label="' + window.OverDrive.SiteCopy.get("ariaLabels.libraryLink", { "libraryName": val.consortium, "title": window.OverDrive.titleName }) + '" href="http://' + val.libraryUrl + '/ContentDetails.htm?id=' + reserveId + '" data-name="' + val.name + '" data-id="' + val.id + '">' + val.name + ' (' + val.consortium + ')</a>';
            tableSelectorString += '<h6>' + window.OverDrive.SiteCopy.get("findInLibrariesPage.distanceLabel") + ': </h6>';
            tableSelectorString += '<div class="mobileDistance">' + distanceHtml + '</div>';
            tableSelectorString += '</div>';
            tableSelectorString += '<div class="largerView">';
            tableSelectorString += '<a class="libraryLink" href="' + titleAtLibrarySiteUrl + '" aria-label="' + window.OverDrive.SiteCopy.get("ariaLabels.libraryLink", { "libraryName": val.consortium, "title": window.OverDrive.titleName }) + '" data-name="' + val.name + '" data-id="' + val.id + '">' + val.name + ' (' + val.consortium + ')</a>';
            tableSelectorString += '</div>';
            tableSelectorString += '</td>';
            tableSelectorString += '<td class="distanceCol">' + distanceHtml + '</td>';
            tableSelectorString += '<td class="gacLinkCol">' + gacHtml + '</td>';
            tableSelectorString += '<td class="viewButtonCol"><a tabindex="0" aria-label="' + window.OverDrive.SiteCopy.get("ariaLabels.libraryLink", { "libraryName": val.consortium, "title": window.OverDrive.titleName }) + '" class="btn btn--small" href="' + titleAtLibrarySiteUrl + '" data-name="' + val.name + '" data-id="' + val.id + '" target="blank">' + buttonText + '</a></td>';
            tableSelectorString += '</tr>';

            $(tableSelector).append(tableSelectorString);

            count = count + 1;
        });

        var totalItems = result.aaData.length;
       
        if (totalItems > window.OverDrive.minNumOfLibraries) {
            $(".seeMore").show();
            updateShowingNumberText(window.OverDrive.minNumOfLibraries, totalItems);

            $(".seeMore").on("click", function() {
                $("tr").removeClass("hiddenLibrary");
                $(".seeMore").hide();
                updateShowingNumberText(totalItems, totalItems);

            });
        } else {
            $(".seeMore").hide();
            updateShowingNumberText(totalItems, totalItems);
        }
    }

    var noResultsTable = function (string, showLibrariesPageLink) {
        $(tableSelector).empty();

        $(tableSelector).addClass('empty');
        $(tableSelector).append('<tr><td>' + string + '</td></tr>');

        if (showLibrariesPageLink) $('.seeMoreLibraries a').text(window.OverDrive.SiteCopy.get("findInLibrariesPage.findInOtherLibraries"));

        //remove the bottom text 
        $(".bottomLink").hide();

        //hide headers
        $(".tableHeader").hide();
    }

    var emptyTableString = function () {
        var string = '<h6>' + window.OverDrive.SiteCopy.get("findInLibrariesPage.noLibrariesFound") + '</h6>';
        string += '<p>' + window.OverDrive.SiteCopy.get("findInLibrariesPage.pleaseTryAgain") + '</p>';
        string += '<ul>';
        string += '<li>' + window.OverDrive.SiteCopy.get("findInLibrariesPage.doesNotHaveTitle") + '</li>';
        string += '<li>' + window.OverDrive.SiteCopy.get("findInLibrariesPage.doesNotOfferTitle") + '</li>';
        string += '<ul>';

        return string;
    }

    var addLibrariesLink = function () {
        $('.dataTables_info').hide();
        $('.seeMoreLibraries').show();
    }

    var getGeoPosition = function () {
        navigator.geolocation.getCurrentPosition(processPositionData, failToGetGeoPosition);
        OverDrive.GA.record.location.clicked('Get precise location');
    }

    var failToGetPosition = function (usedGeo) {
        var html = geocodeFailure;
        html += ".";

        noResultsTable(html, false);
    }

    var processPositionData = function (data) {
        var loc = data.coords.latitude + "," + data.coords.longitude;
        positionTable = setupDataTable("&position=" + loc);
        $('#positionResult').css({ 'display': 'block' });
        $('.library-search-bar__locator').addClass("filled");

        // Update see more libraries link with location data
        if (window.OverDrive.useIpLocation == true && $('.seeMoreLibraries a').length > 0) {
            var link = $('.seeMoreLibraries a').attr("href");

            if (link != "") {
                link += `&lat=${data.coords.latitude}&lon=${data.coords.longitude}`;
                $('.seeMoreLibraries a').attr("href", link);
            }
        }
    }

    var processPositionDataWithIp = function () {
        positionTable = setupDataTable("&position=" + window.OverDrive.location);
        $('#positionResult').css({ 'display': 'block' });
    }

    var processSearchData = function () {
        var searchValue = $("#inlineLibraryFilter").val();

        searchTable = setupDataTable("&q=" + searchValue);
        $('#positionResult').css({ 'display': 'none' });
    }

    var initialize = function () {
        if (window.OverDrive.location && window.OverDrive.location !== "") {
            processPositionDataWithIp();
        } else {
            navigator.geolocation.getCurrentPosition(processPositionData, function () {
                failToGetPosition(false);
            });
        }

        $('body').on('click', 'table.dataTable a', function () {
            var $this = $(this);
            var name = $this.data('name');
            var id = $this.data('id');

            OverDrive.GA.record.library.visited(name, id);
        });

        if (window.OverDrive.useIpLocation == true) {
            $('.icon-location').on('click', function () {
                navigator.geolocation.getCurrentPosition(processPositionData, function () {
                    if (window.OverDrive.location !== "") {
                        processPositionDataWithIp();
                    } else {
                        failToGetPosition(false);
                    }
                });
            });
        }

        $(".library-search-bar__form").submit(function (e) {
            e.preventDefault();

            processSearchData();
        });
    };

    var undoFiltering = function (){
        $(".isSchool").show();
        $(".isPublic").show();
        $(".seeMore").hide();

        var count = $("tr").length;
        updateShowingNumberText(count, count);
    }

    var filterForOnlySchools = function () {
        $(".hiddenLibrary").show();
        $(".isPublic").hide();
        $(".seeMore").hide();

        var total = $("tr").length;
        var count = total - $(".isPublic").length;
        updateShowingNumberText(count, count);
    }

    var filterForOnlyPublicLibraries = function () {
        $(".hiddenLibrary").show();
        $(".isSchool").hide();
        $(".seeMore").hide();

        var count = $(".isPublic").length;
        updateShowingNumberText(count, count);
    }

    var updateShowingNumberText = function (shownEntries, totalEntries) {
        var hideSemiColon = $(".seeMore").css("display") === "none";
        var showingText = window.OverDrive.SiteCopy.get("findInLibrariesPage.showingTitles", { "num": shownEntries, "total": totalEntries });

        var semicolonText = hideSemiColon ? "" : " : ";
        $(".showingEntriesText").text(showingText + semicolonText);
    }

    return {
        initialize: initialize,
        filterForOnlySchools: filterForOnlySchools,
        filterForOnlyPublicLibraries: filterForOnlyPublicLibraries,
        undoFiltering: undoFiltering
    };
}

module.exports = OverDrive.LibrarySearch;