var librarySearchModule = require('../../overdrive.library-search');

$(function () {
    // Variables
    var blurbOpen = false;

    // Functions
    var toggleBlurbSize = function (e) {
        if (blurbOpen) {
            $('.title-page__blurb').addClass('short');
            $(e.currentTarget).text(window.OverDrive.SiteCopy.get("detailsPage.readMore"));
            blurbOpen = false;
        }
        else {
            $('.title-page__blurb').removeClass('short');
            $(e.currentTarget).text(window.OverDrive.SiteCopy.get("detailsPage.readLess"));
            blurbOpen = true;
        }
    };

    //event handlers
    $('.read-more-button').on('click', toggleBlurbSize);
});

$(document).ready(function () {
    getHeroBackground();

    // Library finder
    if (window.OverDrive.isLoggedIn && window.OverDrive.savedLibrariesUrl) {
        $.ajax({
            url: window.OverDrive.savedLibrariesUrl,
            cache: false,
            success: function (data) {
                $("#acquire-bar").html(data);
            }
        });
    } else {
        var librarySearchObject = new librarySearchModule(
            window.OverDrive.reserveID,
            window.OverDrive.timeoutText,
            window.OverDrive.enericErrorText,
            window.OverDrive.librarySearchUrl,
            window.OverDrive.geocodeFailureText,
            window.OverDrive.getPositionUrl,
            true,
            window.OverDrive.librariesUrl,
            window.OverDrive.titleId
        );
        librarySearchObject.initialize();
    }


    // Related titles
    if (window.OverDrive.relatedTitlesUrl) {
        $("#relatedTitles").load(window.OverDrive.relatedTitlesUrl, function () {
            $(window).trigger('resize'); //Get Sticky footer to recalc positioning
            $('.result img').unveil(650, function () {
                $(this).load(function () {
                    $(this).css({ 'width': '100%', 'marginTop': '0' });
                });
            });
        });
    }


    // Related format
    if (window.OverDrive.relatedFormatTitleUrl) {
        $(".related-format-title").load(window.OverDrive.relatedFormatTitleUrl);
    }


    // Get series data
    if (window.OverDrive.seriesSlug) {
        var seriesUrl = window.OverDrive.getSeriesUrl;
        loadSeriesData();
    
        function loadSeriesData() {
            $("#title-page__series").load(seriesUrl, function () {
                $(window).trigger('resize'); // Get Sticky footer to recalc positioning
                $("#series-language").change(function (e) {
                    refreshSeriesData(e.target.value);
                });
            });
        }

        function refreshSeriesData(titleId) {
            seriesUrl = window.OverDrive.seriesUrlBlankTitle;
            seriesUrl = seriesUrl.replace("TITLEID", titleId);

            $("#title-page__series").empty();
            loadSeriesData();
        }
    }


    // Cover treatment for audiobooks
    if (window.OverDrive.isAudiobook) {
        if (coverIsLightColor(window.OverDrive.coverColor)) {
            $('.icon-headphone svg').css("stroke", "black");
        }

        var coverRatio = 1;

        if (window.OverDrive.coverHeight != 0 && window.OverDrive.coverWidth != 0) {
            coverRatio = window.OverDrive.coverHeight / window.OverDrive.coverWidth;
        } else {
            coverRatio = $('.cover img').outerHeight() / $('.cover img').outerWidth();
        }

        setCoverSize(coverRatio);

        function setCoverSize(ratio) {
            var coverSectionWidth = $('.cover').outerWidth();
            $('.cover').css('height', coverSectionWidth * ratio);
        }

        $(window).resize(function () {
            setCoverSize(coverRatio);
        });
    }

    // Adjust metadata padding height
    calculateMetadataPadding();

    $(window).resize(function () {
        calculateMetadataPadding();
    });
});

function coverIsLightColor(color) {
    var r, g, b, hsp;

    if (color.match(/^rgb/)) {
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
    } else {
        color = +("0x" + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
    }

    hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );

    if (hsp > 200) { return true; }

    return false;
}

function getHeroBackground() {
    var imageSrc = $('#sample-target img').attr('src');
    var backgroundString = `background-image: url(${imageSrc}); \
                            background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 101, 149, 0.95)), to(rgba(6,35, 64, 0.95))), url(${imageSrc}); \
                            background-image: -webkit-linear-gradient(top, rgba(0, 101, 149, 0.95) 0%, rgba(6,35, 64, 0.95) 100%), url(${imageSrc}); \
                            background-image: -moz-linear-gradient(top, rgba(0, 101, 149, 0.95) 0%, rgba(6,35, 64, 0.95) 100%), url(${imageSrc}); \
                            background-image: -o-linear-gradient(top, rgba(0, 101, 149, 0.95) 0%, rgba(6,35, 64, 0.95) 100%), url(${imageSrc}); \
                            background-image: linear-gradient(to bottom, rgba(0, 101, 149, 0.95) 0%, rgba(6,35, 64, 0.95) 100%), url(${imageSrc});`;

    $('.title-hero').attr('style', backgroundString);
}

function calculateMetadataPadding() {
    var paddingAmount = $('.title-page__cover').height() - $('.title-hero').height();
    if (paddingAmount > 0) {
        $('.title-page__wrapper').css("padding-top", `calc(${paddingAmount}px + 3em)`);
    }
}